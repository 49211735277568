var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-row',[_c('a-col',{attrs:{"span":22}},[_c('a-form-model',{attrs:{"colon":false,"model":_vm.form,"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"名称"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"分类"},model:{value:(_vm.form.category),callback:function ($$v) {_vm.$set(_vm.form, "category", $$v)},expression:"form.category"}},_vm._l((_vm.categoryList),function(item){return _c('a-select-option',{key:item,attrs:{"value":item}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)],1),_c('a-col',{attrs:{"span":2}},[_c('div',{staticClass:"right",staticStyle:{"margin-top":"4px"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.push(_vm.$route.path + '/add')}}},[_vm._v("发布案例")])],1)])],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"标题","data-index":"title"}}),_c('a-table-column',{attrs:{"title":"分类","data-index":"category"}}),_c('a-table-column',{attrs:{"title":"简介","data-index":"content"}}),_c('a-table-column',{attrs:{"title":"规模","data-index":"scale"}}),_c('a-table-column',{attrs:{"title":"地点","data-index":"address"}}),_c('a-table-column',{attrs:{"title":"获奖情况","data-index":"award"}}),_c('a-table-column',{attrs:{"title":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [(text.reviewStatus === 'notReady')?_c('a-badge',{attrs:{"color":"#f50","text":"未审核"}}):_vm._e(),(text.reviewStatus === 'approved')?_c('a-badge',{attrs:{"color":"#1890ff","text":"已审核"}}):_vm._e()]}}])}),_c('a-table-column',{attrs:{"title":"操作","align":"right","width":"140px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',[(
                _vm.$getPermission(_vm.$route.path + '/review') &&
                text.reviewStatus === 'notReady'
              )?_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.approveText(text)}}},[_vm._v("审核通过")]):_vm._e(),(text.reviewStatus === 'approved')?_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.preview(text)}}},[_vm._v("预览")]):_vm._e(),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.$router.push(_vm.$route.path + '/edit?id=' + text.id)}}},[_vm._v("编辑")]),_c('a',{staticClass:"danger",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.deleteText(text)}}},[_vm._v("删除")])])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }